import * as React from "react";
import { formatNumericDate } from "../../../utils.ts";
import BlogCard from "./Card.jsx";
import H2 from "../../Headings/H2";
import ContentBlock from "./ContentBlock.jsx";

export default function BlogCardGrid({ title, subtitle, data, recent }) {
  let blogPosts = data.edges.map((edge) => ({
    title: edge.node.title,
    href: `/blog/${edge.node.slug}/`,
    datetime: edge.node.updatedAt,
    date: formatNumericDate(edge.node.createdAt),
    image: edge.node.heroImage ? edge.node.heroImage.localFile : null,
    alt: edge.node.heroImage.description,
  }));

  if (recent) {
    blogPosts = blogPosts.splice(0, recent);
  }

  return (
    <ContentBlock>
      <H2 title={title} subtitle={subtitle} />
      <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        {blogPosts.map((post) => (
          <BlogCard post={post} key={post.title} />
        ))}
      </div>
    </ContentBlock>
  );
}
