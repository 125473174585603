import * as React from "react";
import IconSelector from "./IconSelector";

export default function Centered2x2Grid({
  title,
  subtitle,
  introText,
  features,
}) {
  return (
    <div className="bg-white my-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          {title && (
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
              {title}
            </h2>
          )}
          {subtitle && (
            <p className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {subtitle}
            </p>
          )}
          {introText && (
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              {introText}
            </p>
          )}
        </div>

        <div className="mt-10">
          <dl className="space-y-5 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <IconSelector iconString={feature.icon} />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name} Here
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
