import * as React from "react";
import {
  GlobeAltIcon,
  ClockIcon,
  ChatIcon,
  BadgeCheckIcon,
  AcademicCapIcon,
  ExclamationCircleIcon,
  LibraryIcon,
  BookOpenIcon,
  CreditCardIcon,
  SpeakerphoneIcon,
  UserGroupIcon,
  AdjustmentsIcon,
  UsersIcon,
} from "@heroicons/react/outline";

export default function IconSelector({ iconString }) {
  switch (iconString) {
    default:
      return <ExclamationCircleIcon className="h-6 w-6" />;
    case "AcademicCapIcon":
      return <AcademicCapIcon className="h-6 w-6" />;
    case "BadgeCheckIcon":
      return <BadgeCheckIcon className="h-6 w-6" />;
    case "ClockIcon":
      return <ClockIcon className="h-6 w-6" />;
    case "GlobeAltIcon":
      return <GlobeAltIcon className="h-6 w-6" />;
    case "LibraryIcon":
      return <LibraryIcon className="h-6 w-6" />;
    case "BookOpenIcon":
      return <BookOpenIcon className="h-6 w-6" />;
    case "CreditCardIcon":
      return <CreditCardIcon className="h-6 w-6" />;
    case "SpeakerphoneIcon":
      return <SpeakerphoneIcon className="h-6 w-6" />;
    case "ChatIcon":
      return <ChatIcon className="h-6 w-6" />;
    case "UserGroupIcon":
      return <UserGroupIcon className="h-6 w-6" />;
    case "AdjustmentsIcon":
      return <AdjustmentsIcon className="h-6 w-6" />;
    case "UsersIcon":
      return <UsersIcon className="h-6 w-6" />;
  }
}
