import * as React from "react";

export default function PageBody({ children, limitMaxWidth }) {
  let pageClassName = "text-lg mx-auto";
  if (limitMaxWidth) {
    pageClassName += " max-w-prose";
  }

  return (
    <div id="modal-trigger" className="relative py-8 bg-white z-10">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className={pageClassName}>{children} </div>
      </div>
    </div>
  );
}
