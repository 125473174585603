import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import placeholderImage from "../../../images/amador-loureiro-BVyNlchWqzs-unsplash.jpg";

export default function BlogCard({ post }) {
  return (
    <a href={post.href} className="flex mt-2">
      <div
        key={post.title}
        className="flex flex-col rounded-lg shadow-lg overflow-hidden"
      >
        <div className="flex-shrink-0">
          {post.image ? (
            <GatsbyImage image={getImage(post.image)} alt={post.alt} />
          ) : (
            <img
              className="h-48 w-full object-cover"
              src={placeholderImage}
              alt="placeholder"
            />
          )}
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
          </div>
          <div className="mt-6 flex items-center">
            <div className="ml-3">
              <div className="flex space-x-1 text-sm text-gray-500">
                <time dateTime={post.datetime}>{post.date}</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}
