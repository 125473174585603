import * as React from "react";

export default function h2({ title, subtitle, align = "center" }) {
  return (
    <div className={`text-${align}`}>
      <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
        {title}
      </h2>
      {subtitle && (
        <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
          {subtitle}
        </p>
      )}
    </div>
  );
}
