/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import {
  BookOpenIcon,
  VideoCameraIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { triggerSignUpBegin } from "../analytics-helpers";

export default function AlternatingSignUp({ setShowModal }) {
  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <BookOpenIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  The ultimate platform
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Lingua Fonica provides the most premium online learning
                  service there is available. Our custom platform is geared
                  towards being the best experience for our users and we
                  continually improve based on feedback from our students and
                  tutors. You can help us shape your learning platform and
                  experience to meet not only your needs, but your desires! We
                  strive to make our platform a safe place for language
                  learners. All Lingua Fonica tutors are verified either by us
                  directly or by our partner company Conversation Piece, who
                  have been vetting tutors and providing lessons since the
                  1990’s.
                </p>
                <div className="mt-6">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(true);
                      triggerSignUpBegin();
                    }}
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Get started
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;My Spanish speaking skills have greatly increased
                    thanks to my tutor, I am happy that I gave Lingua Fonica a
                    try.&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      {/* Profile image */}
                      {/* <img
                        className="h-6 w-6 rounded-full"
                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      /> */}
                      <UserCircleIcon
                        className="h-6 w-6 rounded-full"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Liam O’Hanlon
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              {/* Top image */}
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="/signUp.png"
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <VideoCameraIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  We care, we really do…
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Lingua Fonica is dedicated to providing the best for our
                  learners. We make sure that all tutors on our site are
                  passionate about teaching, language, and culture. We want
                  every learner to reach their language learning goals…and enjoy
                  doing it! We want you to become just as passionate and
                  enthusiastic about language and culture as we are. So, let
                  Lingua Fonica become a part of your goals!
                </p>
                <div className="mt-6">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(true);
                      triggerSignUpBegin();
                    }}
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Get started
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              {/* Bottom image */}
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="/lessonPage.png"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
