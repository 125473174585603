import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Centered2x2Grid from "../components/Centered2x2grid";
import PageBody from "../components/PageBody";
import AlternatingSignUp from "../components/AlternatingSignUp";
import BlogCardGrid from "../components/Blogs/Cards/Grid";
import { defaultFields as leadFormFields } from "../config/ContactForm.data";

// Data
import { createSeoData } from "../utils/page";

function IndexPage({ data }) {
  // TODO utility functions to create the data
  const seo = createSeoData(data.contentfulHomePage);
  const hero = {
    heroTitleLine1: data.contentfulHomePage.heroTitle,
    heroTitleLine2: data.contentfulHomePage.heroTitleLine2,
    heroContent: data.contentfulHomePage.heroContent.heroContent,
  };
  return (
    <Layout seo={seo} hero={hero} leadFormFields={leadFormFields}>
      <PageBody>
        <Centered2x2Grid
          title={data.contentfulHomePage.grid2x2.title}
          subtitle={data.contentfulHomePage.grid2x2.subtitle}
          introText={data.contentfulHomePage.grid2x2.introText}
          features={data.contentfulHomePage.grid2x2.content}
        />
      </PageBody>
      <AlternatingSignUp />
      <BlogCardGrid
        title="Blog Posts"
        subtitle={<Link to="/blog">Click to view the latest blogs</Link>}
        data={data.allContentfulBlogPost}
        recent={3}
      />
    </Layout>
  );
}

export const query = graphql`
  {
    contentfulHomePage {
      heroTitle
      heroContent {
        heroContent
      }
      metaDescription
      heroTitleLine2
      heroImage {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 800, formats: [AUTO, WEBP])
          }
        }
      }
      grid2x2 {
        subtitle
        title
        introText
        content {
          icon
          name
          description
        }
      }
    }

    allContentfulBlogPost (sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          slug
          title
          heroImage {
            description
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 450
                  height: 200
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          body {
            raw
          }
          createdAt
        }
      }
    }
  }
`;

export default IndexPage;
